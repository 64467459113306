import React, { useEffect } from "react";
import * as s from "./index.module.scss";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { graphql } from "gatsby";
import { SliceZone } from "@prismicio/react";
import get from "lodash/get";
import Layout from "../../components/Layout";
import CommonPageHeroSection from "../../components/CommonPage/HeroSection";
import AccordionSection from "../../components/CommonPage/AccordionSection";
import CardsSection from "../../components/SEO pages/PersonalInsuarancePage/cards";

const SEOPersonalInsuaranceTemplate = ({ data }) => {
  const pageData = get(data, "prismicSeoPersonalInsurancePage.dataRaw", {});
  const components = {
    custom_accordion_section: AccordionSection,
    cardsslice: CardsSection,
  };

  useEffect(() => {
    const fade = document.querySelectorAll(".fadeIn");
    let options = {
      root: document.querySelector("#scrollArea"),
      rootMargin: "0px",
      threshold: 0.65,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry, idx) => {
        if (entry.intersectionRatio > 0) {
          entry.target.style.animation = `fadeIn 0.5s forwards linear`;
        } else {
          entry.target.style.color = "none";
        }
      });
    }, options);

    fade.forEach((fade) => {
      observer.observe(fade);
    });
  }, []);

  return (
    <>
      <Layout data={pageData} doc={data?.prismicSeoPersonalInsurancePage}>
        <div className={s.bg_color}>
          <CommonPageHeroSection data={pageData} />
          <SliceZone slices={pageData.body} components={components} />
        </div>
      </Layout>
    </>
  );
};

export default withPrismicPreview(SEOPersonalInsuaranceTemplate);

export const query = graphql`
  query seoPersonalInsuranceQuery($lang: String) {
    prismicSeoPersonalInsurancePage(lang: { eq: $lang }) {
      dataRaw
      lang
      alternate_languages {
        uid
        type
        lang
      }
    }
  }
`;
